import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Container from '../components/Container'
import get from 'lodash/get'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { ArticleJsonLd } from 'gatsby-plugin-next-seo';
import { FAQJsonLd } from 'gatsby-plugin-next-seo'
import PageTitle from '../components/PageTitle'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from "@contentful/rich-text-types"
import casinolistStyle from "../components/Casinolist.module.css"

class Posts extends React.Component {
  //LOAD MORE
  constructor(props) {
   super(props);
   this.state = {
     items: [],
     visible: 20,
     error: false,
   };
   this.loadMore = this.loadMore.bind(this);
   this.showLess = this.showLess.bind(this);
 }
 loadMore() {
   this.setState((prev) => {
     return { visible: prev.visible + 20 };
   });
 }
 showLess() {
   this.setState((prev) => {
     return { visible: 20 };
   });
 }
componentDidMount() {
var links = document.links;
for (var i = 0, linksLength = links.length; i < linksLength; i++) {
if (links[i].hostname != window.location.hostname) {
   links[i].target = '_blank';
   links[i].rel = 'noopener';
}
}
var lisa = document.getElementsByClassName("tab-lisa");
var bonus = document.getElementsByClassName("tab-bonus");
var i;
for (i = 0; i < lisa.length; i++) {
lisa[i].addEventListener("click", function() {
this.classList.toggle("active");
if (this.parentNode.nextElementSibling.childNodes[0].style.display === "block") {
 this.parentNode.nextElementSibling.childNodes[0].style.display = "none";
} else {
 this.parentNode.nextElementSibling.childNodes[0].style.display = "block";
 this.parentNode.nextElementSibling.childNodes[1].style.display = "none";
 this.parentNode.nextElementSibling.childNodes[2].style.display = "none";
 this.nextElementSibling.classList.remove("active");
 this.nextElementSibling.nextElementSibling.classList.remove("active");
}
});
}
var j;
for (j = 0; j < bonus.length; j++) {
bonus[j].addEventListener("click", function() {
this.classList.toggle("active");
if (this.parentNode.nextElementSibling.childNodes[1].style.display === "block") {
 this.parentNode.nextElementSibling.childNodes[1].style.display = "none";
} else {
 this.parentNode.nextElementSibling.childNodes[1].style.display = "block";
 this.parentNode.nextElementSibling.childNodes[0].style.display = "none";
 this.parentNode.nextElementSibling.childNodes[2].style.display = "none";
 this.nextElementSibling.classList.remove("active");
 this.previousElementSibling.classList.remove("active");
}
});
}					
var tahdet = document.getElementsByClassName("tahdet");
var t;
for (t = 0; t < tahdet.length; t++) {
var two = "<img src='/images/star2.webp' alt='2' class='lazyload'/>"
var twohalf = "<img src='/images/star2half.webp' alt='2' class='lazyload'/>"
var three = "<img src='/images/star3.webp' alt='3' class='lazyload'/>"
var threehalf = "<img src='/images/star3half.webp' alt='3' class='lazyload'/>"
var four = "<img src='/images/star4.webp' alt='4' class='lazyload'/>"
var fourhalf = "<img src='/images/star4half.webp' alt='4' class='lazyload'/>"
var five = "<img src='/images/star5.webp' alt='5' class='lazyload'/>"
var rate = tahdet[t].innerHTML;
var star = rate.replace("2", two).replace("Two and half", twohalf).replace("3", three).replace("3 and half", threehalf).replace("4", four).replace("4 and half", fourhalf).replace("5", five);
tahdet[t].innerHTML = star;
}
}
  render() {
    const data = get(this, 'props.data.contentfulFrontpage')
    return(
      <Layout>
      <ArticleJsonLd
      url='https://onlinecassinos.org/'
      headline='Cassinos Online | As melhores opções para jogadores brasileiros'
      images={[
        'https://onlinecassinos.org/images/cassinosonline.webp',
      ]}
      datePublished='2024-01-04'
      dateModified='2024-01-04'
      authorName='Javier Garcia'
      description='Cassinos Online | As melhores opções para jogadores brasileiros'
      publisherName='Online Cassinos'
      publisherLogo='https://onlinecassinos.org/images/cassinosonline.webp'
      overrides={{
        '@type': 'techArticle', 
        'profiencyLevel':'Expert'
      }}
    />
    <GatsbySeo
      title='Cassinos Online | As melhores opções para jogadores brasileiros'
      description='-'  
    />
     <FAQJsonLd
        questions={[
          { question: 'É o jogo online legal no Brasil?', answer: 'Embora o Brasil tenha restrições rigorosas ao jogo, não reconhece o jogo online dentro de suas fronteiras. No entanto, os jogadores brasileiros podem escolher entre vários sites de cassino legítimos que operam fora do país. Essas plataformas permitem que os jogadores se registrem, depositem, joguem e ganhem sem violar nenhuma lei.' },
          { question: 'Que tipos de jogos os jogadores brasileiros podem desfrutar?', answer: 'Os jogadores brasileiros têm acesso a uma ampla variedade de jogos em plataformas de cassinos online internacionais. Isso inclui jogos de caça-níqueis, bingo, poker, raspadinhas, jogos de mesa com gerador de números aleatórios e jogos com dealers ao vivo. Embora os cassinos online locais ainda não sejam regulamentados, os jogadores ainda podem desfrutar de opções de jogos diversificadas.' },
          { question: 'Os brasileiros podem apostar em esportes online?', answer: 'As apostas esportivas no Brasil tiveram desenvolvimentos recentes. As apostas esportivas offline são permitidas por meio de loterias estaduais ou nacionais, enquanto as apostas esportivas online foram legalizadas em 2018. Empresas estrangeiras de apostas esportivas podem operar legalmente no Brasil se possuírem uma licença internacional válida. No entanto, o quadro legal para as apostas esportivas permanece incerto após o vencimento da lei inicial em 2022.' },
          { question: 'O poker é legal no Brasil?', answer: 'O poker é reconhecido como um jogo de habilidade, não de sorte, no Brasil. Como resultado, entidades privadas podem organizar legalmente jogos de poker offline. Essa aceitação estabelece um precedente para futuros desenvolvimentos legais no cenário de jogos de azar do país.' },
          { question: 'E quanto aos jogos de loteria?', answer: 'Quando conduzidos pelo governo ou pelos estados individuais, os jogos de loteria são legais no Brasil. Cada um dos 26 estados administra sua própria loteria, e o banco nacional, Caixa, opera a loteria no Distrito Federal de Brasília.' },
          { question: 'Como os jogadores brasileiros podem acessar cassinos online?', answer: 'Os jogadores brasileiros podem se juntar a cassinos online internacionais que atendem às suas necessidades. Essas plataformas oferecem vários métodos de pagamento adaptados ao Brasil, incluindo cartões de débito e crédito, carteiras eletrônicas, PIX e criptomoedas. O suporte ao cliente está disponível em português, e os cassinos são licenciados e regulamentados por instituições respeitáveis.' },
          { question: 'Qual é o status legal dos contratos de cassinos online?', answer: 'Contratos entre sites estrangeiros de jogos de azar online e jogadores brasileiros são legais de acordo com o Código Civil Brasileiro. Desde que os jogadores cumpram a legislação do país para jogos de azar online, eles podem se registrar e jogar em cassinos online internacionais sem repercussões legais.' },
        ]}
      />
      <Container>
      <PageTitle>Cassinos Online</PageTitle>
      <div className="ylaosa">{documentToReactComponents(data.ylosanTeksti1.json, {
        renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: node => (
            <img className="lazyload page-img"
              data-src={`${node.data.target.fields.file["en-US"].url}`}
              alt={node.data.target.fields.title["en-US"]}
            />
          ),
        },
      })}</div>
      <center><h2>&#127463;&#127479; Os melhores cassinos online para jogadores brasileiros &#127463;&#127479;</h2></center>
      <table>
        <tbody>
          {data.etusivuBonuslista .slice(0, this.state.visible) .map(({transparentLogo,name,afflink,casinodetails,bonusfield,freeSpins}) => (
          <tr className={casinolistStyle.casino}>
            <td className={casinolistStyle.top}>
              <div className={casinolistStyle.logo}>
                <img data-src={transparentLogo.file.url} alt={transparentLogo.title} width="180px" height="94px" className="lazyload"/>
                <div className={casinolistStyle.name}>
                <b><p className={casinolistStyle.title}>{name}</p></b>
                </div>
              </div>
              <div className={casinolistStyle.content}>
                <div className={casinolistStyle.contentBox}>
                  <div className={casinolistStyle.contentInfo}>
                    <div className={casinolistStyle.offerItem}>
                      <i>Bônus</i>
                      <h4><div>{bonusfield.brazilBonus}</div></h4>
                    </div>
                    <div className={casinolistStyle.offerItem}>
                      <i>Rodadas grátis</i>
                      <h4>{freeSpins.argentinaFs}</h4>
                    </div>
                  </div>
                  <div className={casinolistStyle.contentBtn}>
                    <a className={casinolistStyle.btn} href={afflink} target="_blank">Visitar cassino</a>
                  </div>
                </div>
              </div>
            </td>
            <td className={casinolistStyle.bottom}>
              <div className={casinolistStyle.pikaexpand}>
              {documentToReactComponents(casinodetails.json)}
              </div>
            </td>
          </tr>
          ))}
        </tbody>
      </table>
      <div className="loadBtn">
      {this.state.visible > 20 && (
                      <button
                        onClick={this.showLess}
                        type="button"
                        id="showLess"
                      >
                        Menos
                      </button>
                    )}
                    {this.state.visible < data.etusivuBonuslista.length && (
                      <button
                        onClick={this.loadMore}
                        type="button"
                        id="loadMore"
                      >          
                        Mais cassinos
                      </button>
                    )}
                  </div>
      <div>
        <h2>Perguntas frequentes</h2>
        <div>
          <div><h5>É o jogo online legal no Brasil? </h5></div>
          <p>Embora o Brasil tenha restrições rigorosas ao jogo, não reconhece o jogo online dentro de suas fronteiras. No entanto, os jogadores brasileiros podem escolher entre vários sites de cassino legítimos que operam fora do país. Essas plataformas permitem que os jogadores se registrem, depositem, joguem e ganhem sem violar nenhuma lei.</p>
        </div>
        <div>
          <div><h5>Que tipos de jogos os jogadores brasileiros podem desfrutar? </h5></div>
          <p>Os jogadores brasileiros têm acesso a uma ampla variedade de jogos em plataformas de cassinos online internacionais. Isso inclui jogos de caça-níqueis, bingo, poker, raspadinhas, jogos de mesa com gerador de números aleatórios e jogos com dealers ao vivo. Embora os cassinos online locais ainda não sejam regulamentados, os jogadores ainda podem desfrutar de opções de jogos diversificadas.</p>
        </div>
        <div>
          <div><h5>Os brasileiros podem apostar em esportes online? </h5></div>
          <p>As apostas esportivas no Brasil tiveram desenvolvimentos recentes. As apostas esportivas offline são permitidas por meio de loterias estaduais ou nacionais, enquanto as apostas esportivas online foram legalizadas em 2018. Empresas estrangeiras de apostas esportivas podem operar legalmente no Brasil se possuírem uma licença internacional válida. No entanto, o quadro legal para as apostas esportivas permanece incerto após o vencimento da lei inicial em 2022.</p>
        </div>
        <div>
          <div><h5>O poker é legal no Brasil? </h5></div>
          <p>O poker é reconhecido como um jogo de habilidade, não de sorte, no Brasil. Como resultado, entidades privadas podem organizar legalmente jogos de poker offline. Essa aceitação estabelece um precedente para futuros desenvolvimentos legais no cenário de jogos de azar do país.</p>
        </div>
        <div>
          <div><h5>E quanto aos jogos de loteria? </h5></div>
          <p>Quando conduzidos pelo governo ou pelos estados individuais, os jogos de loteria são legais no Brasil. Cada um dos 26 estados administra sua própria loteria, e o banco nacional, Caixa, opera a loteria no Distrito Federal de Brasília.</p>
        </div> 
        <div>
          <div><h5>Como os jogadores brasileiros podem acessar cassinos online? </h5></div>
          <p>Os jogadores brasileiros podem se juntar a cassinos online internacionais que atendem às suas necessidades. Essas plataformas oferecem vários métodos de pagamento adaptados ao Brasil, incluindo cartões de débito e crédito, carteiras eletrônicas, PIX e criptomoedas. O suporte ao cliente está disponível em português, e os cassinos são licenciados e regulamentados por instituições respeitáveis.</p>
        </div>
        <div>
          <div><h5>Qual é o status legal dos contratos de cassinos online? </h5></div>
          <p>Contratos entre sites estrangeiros de jogos de azar online e jogadores brasileiros são legais de acordo com o Código Civil Brasileiro. Desde que os jogadores cumpram a legislação do país para jogos de azar online, eles podem se registrar e jogar em cassinos online internacionais sem repercussões legais.</p>
        </div>                                
      </div>
      </Container>
    </Layout>
    )
  }
}

export default Posts

export const query = graphql`
query($skip: Int!, $limit: Int!) {
  allContentfulPost(
    sort: { fields: [publishDate], order: DESC }
    limit: $limit
    skip: $skip
  ) {
    edges {
      node {
        title
        id
        slug
        publishDate(formatString: "MMMM DD, YYYY")
        heroImage {
          title
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          ogimg: resize(width: 1800) {
            src
          }
        }
        body {
          childMarkdownRemark {
            timeToRead
            html
            excerpt(pruneLength: 80)
          }
        }
      }
    }
  }
    contentfulFrontpage(title: {eq: "Frontpage"}) {
      title
      id
      slug
      ylosanTeksti1 {
        json
      }
      etusivuBonuslista {
        name
        transparentLogo {
          title
          file {
            url
          }
        }
        afflink
        bonusfield {
          brazilBonus
        }
        freeSpins {
          argentinaFs
        }
        casinodetails {
          json
          }
        bonusdetails {
          json
        }
      }
    }
  }
`

